<template>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" style="min-height: 400px">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-responsive-md">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>User</th>
                                        <th>Bonus received</th>
                                        <th>Created</th>
                                        <!--                                    <th></th>-->
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="bonuses.length > 0">
                                        <tr v-for="(bonus, idx) in bonuses" :key="idx">
                                            <td>
                                                <strong>{{ idx+1 }}</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span class="w-space-no">{{ bonus.data.metadata.from }}</span>
                                                </div>
                                            </td>
                                            <td>${{bonus.data.amount}}	</td>
                                            <td>{{getReadableDate(bonus.data.createdAt)}}</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="6">
                                                <div class="col-12 text-center mt-4">
                                                    <h6 class="text-info">Empty referral history</h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import methodsMixin from "../../utils/methodsMixin";

export default {
    name: "Referral",
    data(){
        return {
            fetch_interval: null,
            receivers: []
        }
    },
    mixins: [methodsMixin],
    computed: {
        ...mapGetters('bonus', {
            bonuses: 'getBonuses'
        })
    },
    methods: {
        async fetchMetaInfo(){
            if(this.bonuses.length > 0){
                clearInterval(this.fetch_interval);
                const promises = this.bonuses.map(bonus=>this.$store.dispatch('user/getUser', bonus.data.metadata.from));
                const results = await Promise.all(promises);
                this.receivers = results.map(result=>result.data.data.email)
            }else{
                if(this.fetch_interval === null){
                    this.fetch_interval = setInterval(this.fetchMetaInfo, 2000)
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch('bonus/fetchBonuses').then(()=>this.fetchMetaInfo)
    }
}
</script>

<style scoped>

</style>